import {acceptHMRUpdate, defineStore} from "pinia";
import qs from 'qs';
import {useSearch} from "~/store/search";
import {useRegion} from "~/store/app/region";
import {useClinics} from "~/store/clinics";

export const usePrices = defineStore("prices", {
    state: () => {
        const config = useRuntimeConfig();

        return {
            apiBase: config.public.apiBase,
            cache: config.public.cache,
            listPrices: [],
            listPricesGroup: [],
            textSearch: "",
            isExtendedLoaded: false,
        };
    },

    getters: {
        filterByOnline: (state) => (listPrices, online) => {
            return listPrices.filter(priceId => {
                const price = state.getPriceById(priceId);
                return price.attributes.online === online;
            })
        },
        checkOnline: (state) => (priceId) => {
            const price = this.getPriceById(priceId);
            return price.attributes.online;
        },
        getPriceById: (state) => (priceId) => {
            return state.listPrices.find((item) => +item.id === +priceId);
        },
        filterListPrices: (state) => {
            const searchStore = useSearch();
            const regionStore = useRegion();
            const clinicStore = useClinics();

            function matchesSearchText(price, searchText) {
                if (!searchText || searchText.length < 3) return true;
                const lowerSearchText = searchText.toLowerCase().trim();
                if (price.attributes.title.toLowerCase().includes(lowerSearchText)) {
                    return true;
                }

                console.log(price?.attributes?.Tags)

                return price?.attributes?.Tags?.some(tag => tag.Tag && tag.Tag.toLowerCase().includes(lowerSearchText));
            }

            const filterBySearchText = state.listPrices.filter((price) => {
                return matchesSearchText(price, searchStore.searchText);
            })

            let listClinics = [];
            if (searchStore?.searchClinic?.id && searchStore?.searchClinic?.id !== null) {
                let clinic = clinicStore.getClinicById(searchStore.searchClinic.attributes.clinicId);
                listClinics = [clinic.attributes.clinicId];
            } else {
                listClinics = regionStore.listClinicsRegion();
            }

            return filterBySearchText.filter((price) => {
                return price.attributes.price.some((item) => {
                        return listClinics.includes(item.clinic?.data?.attributes?.clinicId);
                    }
                );
            }).map((price) => {
                const listAddress = [];
                let minPrice = null;
                let fromPrice = false;

                listClinics.forEach((clinicId) => {
                    const priceInClinic = state.priceInClinic(price.id, clinicId);
                    if (!priceInClinic) return;

                    listAddress.push(clinicStore.getClinicById(clinicId).attributes.address);
                    if (minPrice !== null && priceInClinic !== minPrice)
                        fromPrice = true;

                    if (minPrice === null || priceInClinic < minPrice) {
                        minPrice = priceInClinic;
                    }

                    // if (price.attributes.title === 'Онлайн-консультация педиатра (акция)')
                    //     console.log(priceInClinic, minPrice, clinicId, price.id, price.attributes.title)
                })

                return {
                    ...price,
                    listAddress: listAddress.join('<br/>'),
                    minPrice: fromPrice ? 'от ' + minPrice : minPrice,
                };
            }).sort((a, b) => {
                if (a.attributes.title > b.attributes.title) {
                    return 1;
                }
                if (a.attributes.title < b.attributes.title) {
                    return -1;
                }
                return 0;
            })

        },
        createApiUrl: (state) => (url) => {
            return `${state.apiBase}${url}`;
        },
        priceInClinic: (state) => (priceId, clinicId) => {
            const price = state.getPriceById(priceId);

            return price?.attributes?.price.filter(
                (item) => item?.clinic?.data?.attributes?.clinicId === clinicId
            )[0]?.Price;
        },
        hasExtendedPrices: (state) => {
            return state.isExtendedLoaded;
        },
    },

    actions: {
        async fetchPrices(extended = true) {
            const filters = {
                // link: {
                //     $eq: link,
                // },
            };
            let populate = '*';
            if (extended) {
                populate = {
                    specials: {
                        populate: '*',
                    },
                    docs: {
                        // populate: 'deep,1',
                        // clinic: 'deep,2',
                        populate: {
                            photo_mini: {
                                fields: ['url'],
                            },
                            photo: {
                                fields: ['url'],
                            },
                            clinics: {
                                fields: ['clinicId', 'region']
                            },
                            fields: ['link']
                        }
                    },
                    price: {
                        populate: {
                            clinic: {
                                fields: ['clinicId', 'region']
                                // $contains: 'msk'
                            },
                        },
                    },
                    Tags: {
                        populate: '*'
                    }
                };
            }
            const cache = '&cache=get';
            const query = qs.stringify({filters, populate, pagination: {pageSize: 1000}}, {encode: true});
            const url = `/api/prices?${query}${cache}`;

            const startTime = performance.now();
            const listPrices = await fetch(
                this.createApiUrl(
                    url
                )
            )
                .then((response) => response.json())
                .then((result) => result.data)
                .catch((error) => console.log("error", error));

            const endTime = performance.now();
            const durationInSeconds = (endTime - startTime) / 1000;
            this.listPrices = [...listPrices];
            this.isExtendedLoaded = extended;
            console.log(`${durationInSeconds} - ${url}`);
        },
        async fetchPrice(priceId) {
            const startTime = performance.now();
            const url = `/api/prices/${priceId}?populate=deep,3`;
            const price = await fetch(
                this.createApiUrl(url)
            )
                .then((response) => response.json())
                .then((result) => result.data)
                .catch((error) => console.log("error", error));
            if (Object.values(price).length) {
                const endTime = performance.now();
                const durationInSeconds = (endTime - startTime) / 1000;
                console.log(`${durationInSeconds} - ${url}`);
                return price;
            } else {
                console.log("Price is empty");
            }
        },
        addPriceScore(id) {
            let priceIdx = this.listPrices.findIndex((item) => item.id === id);
            if (this.listPrices[priceIdx].score) {
                this.listPrices[priceIdx].score += 300;
            } else {
                this.listPrices[priceIdx].score = 300;
            }
        },
    },
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(usePrices, import.meta.hot));
}
